<template>
  <div class="ml-5">
     <v-card-title>
          <h2 class="">Notifications</h2>
          <v-spacer></v-spacer>
          <v-btn text outlined @click="markRead" class="" large v-if="getNotification > 0"> Mark As Read </v-btn>
      </v-card-title>
    
    <v-card class="mt-5 pb-5 mb-5" :color="ant" min-height="400">
      <v-skeleton-loader
        v-bind="attrs"
        type="list-item-avatar-three-line@2"
        v-if="loading"
      ></v-skeleton-loader>
      <div v-else>
        <v-card-text>
          <PlaceHolder
            v-if="notifications.length === 0"
            :src="require('@/assets/img/svg/no-notifications.svg')"
            title=" Your do not have any notifications"
            text=" Please complete any action such as placing an order, editing an order, hiring a writer, etc., to receive new notifications"
          />
          <v-list v-else color="transparent" three-line>
            <div v-for="(notification, index) in notifications" :key="index" >
              <OrderExpired :notification="notification" v-if="notification.template === 'ORDER_EXPIRED'" />
              <OrderRevision  :notification="notification" v-if="notification.template === 'ANSWER_REVISION'"/>
              <OrderDraft :notification="notification" v-if="notification.template === 'ANSWER_DRAFT'" />
              <OrderFinal :notification="notification" v-if="notification.template === 'ANSWER_FINAL'"/>
              <OrderAssigned :notification="notification" v-if="notification.template === 'ORDER_ASSIGNED'" />
              <OrderCompleted :notification="notification" v-if="notification.template === 'ORDER_COMPLETED'"/>
              <v-divider></v-divider>
            </div>
          </v-list>
        </v-card-text>
      </div>
      <div class="text-center mt-5 pb-10" v-if="notifications.length !== 0 && page !== pagination.lastPage">
        <v-btn text outlined @click="showMore" class="" large> Show more </v-btn>
      </div>
    </v-card>
    <div class="mt-10"></div>
  </div>
</template>

<script>
import colorMxn from '@/mixins/colorMxn';
import generalMxn from '@/mixins/general_mixin';
import PlaceHolder from "@/components/core/PlaceHolder";
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: "Notifications",
  components: {
    PlaceHolder: PlaceHolder,
    OrderExpired: () => import('@/modules/Notifications/_components/OrderExpired'),
    OrderRevision: () => import('@/modules/Notifications/_components/OrderRevision'),
    OrderDraft: () => import('@/modules/Notifications/_components/OrderDraft'),
    OrderFinal: () => import('@/modules/Notifications/_components/OrderFinal'),
    OrderAssigned: () => import('@/modules/Notifications/_components/OrderAssigned'),
    OrderCompleted: () => import('@/modules/Notifications/_components/OrderCompleted'),
  },
  mixins: [colorMxn, generalMxn],
  data() {
    return {
      loading: false,
      msg: "hey message",
      notifications: [],
      page: 1,
      limit: 10,
      pagination: null,
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      }
    };
  },
  computed: {
    ...mapGetters(['getSession', 'getNotification']),
  },
  async mounted() {
    this.getNotifications();
    await this.getBalance();
  },
  methods: {
    ...mapMutations(['setPushNotification', 'setNotification']),
    ...mapActions(['performGetActions', 'performPutActions', 'getBalance']),
    async getNotifications() {
      const payload = {
        page: this.page,
        limit: this.limit,
      };

      const fullPayload = {
        params: payload,
        endpoint: `/get-notification/${this.getSession.id}`,
      };

      const response = await this.performGetActions(fullPayload);
      response.data.forEach((data) => {
        this.notifications.push(data);
      });
      this.pagination = response.pagination;
      const unreadNotification = this.notifications.filter((obj) => !obj.read);
      this.setNotification(unreadNotification.length);
    },
    showMore() {
      this.page = this.page + 1;
      this.getNotifications();
    },
    async markRead() {
      const ids = []
      this.notifications.forEach((obj) => {
        if (!obj.read) {
          ids.push(obj.id);
        }
      });
      
      const payload = {
        ids: ids
      };

      const fullPayload = {
        params: payload,
        endpoint: '/mark-read-notification',
      };

      const response = await this.performPutActions(fullPayload);
      if (response.status) {
        this.setNotification(0);
      }
    }
  }
};
</script>

<style scoped>
.card-header {
  background: #232c3b;
  height: 70px;
  border: 2px solid #273142;
  border-radius: 4px;
}

.icon {
  width: 70px;
  text-align: left;
}
</style>
